.nav-bar {
  position: relative;
  display: block;
  width: 100%;
  height: var(--nav-bar-height);
}

.nav-bar .logo {
  padding-left: 30px;
  float: left;
  line-height: 115px;
}

.nav-bar .logo img {
  height:75px;
  border-radius: 50%;
  border: 2px solid #000;
  display: inline-block;
  vertical-align: middle;
}

.nav-bar-menu {
  text-align: right;
  list-style-type: none;
  font-size: 12px;
  font-weight: bold;
  padding-right: 30px;
  right: 0;
}

@media (max-width: 1279px) {
  .nav-bar-menu {
    position: relative;
    right: 0;
  }
}

.nav-bar-menu > li {
  margin: 0;
}

.nav-bar-menu > li a {
  text-decoration: unset;
  cursor: pointer;
}
